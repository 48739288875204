import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Articles from "../components/blog/Articles/Articles";
import Sidebar from "../components/blog/Sidebar/Sidebar";
import Layout from "../components/common/layout/layout";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";
import Pagination from "../components/pagination/Pagination";

const BlogPage = ({ data, pageContext }) => {
  BlogPage.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
  };
  const articles = data.articles.nodes;
  const categories = data.categories.nodes;

  return (
    <Layout>
      <div className={"o-hero o-hero--blog"}>
        <div className="container p-vertical-xl">
          <h1 className={"a-title a-title--hero"}>
            <strong>Blog</strong> <br />
            Artículos de C&R Seguridad
          </h1>
        </div>
      </div>
      <div className="container grid-70-30 grid-70-30--blog p-vertical-l">
        <main>
          <Articles articles={articles} />
          <Pagination pageContext={pageContext} />
        </main>
        <aside>
          <Sidebar categories={categories} />
        </aside>
      </div>
    </Layout>
  );
};

export const Head = () => (
  <SEO
    title="Blog de C&R Seguridad"
    description="Todas as informações que você precisa saber sobre segurança para sua empresa estão em nosso blog. Confira as últimas entradas."
    robots="index, follow"
    canonical={"blog/"}
  />
);

export const query = graphql`
  query LastArticles($skip: Int!, $limit: Int!) {
    articles: allSanityArticle(
      sort: { order: DESC, fields: date }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        category {
          name
          slug {
            current
          }
        }
        id
        title
        slug {
          current
        }
        thumbnailText
        thumbnail {
          asset {
            gatsbyImageData
            id
            _id
            url
          }
        }
      }
    }
    categories: allSanityCategory(
      sort: { fields: name, order: ASC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        name
        slug {
          current
        }
        id
      }
    }
  }
`;

export default BlogPage;
