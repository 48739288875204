import "./pagination.scss";

import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Pagination: React.FC<any> = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <div className="m-pagination">
      {previousPagePath && (
        <Link
          to={previousPagePath}
          className="a-button a-button--pagination a-button--pagination__prev"
        >
          Página anterior
        </Link>
      )}
      {nextPagePath && (
        <Link
          to={nextPagePath}
          className="a-button a-button--pagination a-button--pagination__next"
        >
          Página siguiente
        </Link>
      )}
    </div>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pagination;
