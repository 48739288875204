import "./sidebar.scss";

import { Link } from "gatsby";
import React from "react";

import isotype from "../../../assets/icons/isotype.svg";

const Sidebar: React.FC<any> = (props: { categories: any }) => {
  const categories = [];
  props.categories.forEach((category) => {
    categories.push(
      <Link
        key={category.slug.current}
        to={`/blog/${category.slug.current}/`}
        className={"a-categories-list-items__link"}
      >
        {category.name}
      </Link>
    );
  });

  return (
    <div className={"o-sidebar text-center"}>
      <div className={"m-sidebar-list"}>
        <h2 className={"a-title a-title--service a-title--categories"}>
          Categorías
        </h2>
        <div className={"a-categories-list-items"}>
          <Link to={`/blog/`} className={"a-categories-list-items__link"}>
            Todas las publicaciones
          </Link>
          {categories}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
