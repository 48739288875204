import "./articles.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";

const Articles: React.FC<any> = (props: { articles: any }) => {
  const articles = [];
  props.articles.forEach((article) => {
    articles.push(
      <div key={article.slug.current}>
        <Link to={`/${article.slug.current}/`}>
          <SanityImage
            {...article.thumbnail}
            width={350}
            alt={article.title}
            className={"a-article-photo"}
          />
        </Link>
        <div className="m-article-desc p-vertical">
          <Link
            key={article.slug.current}
            to={`/${article.slug.current}/`}
            className={"a-title a-title--service a-title--blog"}
          >
            {article.title}
          </Link>
          <p className={"a-desc a-desc--card text-justify"}>
            {article.thumbnailText}
          </p>
        </div>
      </div>
    );
  });

  return <div className={"o-articles grid-x2"}>{articles}</div>;
};

export default Articles;
